import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import './Nav.css';
import {connect} from "react-redux";
import NavConstants from "../../../model/NavConstants";
import {Drawer} from "@material-ui/core";

class Nav extends Component {

    constructor(){
        super();
        this.state = {
            mobileNavShown:false
        }
    }

    // Used to toggle the menu on small screens when clicking on the menu button
    toggleMobileNav() {
        this.setState({mobileNavShown: !this.state.mobileNavShown})
    }

    showMobileNav() {
        this.setState({mobileNavShown: true})
    }

    hideMobileNav() {
        this.setState({mobileNavShown: false})
    }

    navTo(str){
        this.props.history.push(str);
        this.hideMobileNav();
    }


    getHighliteClass(str){
        return '';
    }

    render(){

        return(
            <div>
                <div id='navWrapper'>
                    <div id='logoDiv'>
                        <a  onClick={()=>{this.navTo('/')}} >
                            <img src={process.env.PUBLIC_URL + '/images/Uglyducklingslogo4.png'} alt="Ugly Ducklings Filmset Directory"  />
                        </a>
                    </div>

                    <div id='mainNav' className={this.props.device==='Mobile' ? 'hidden' : 'shown'}>
                        <div>
                            <li><NavLink to={NavConstants.CONTACT}>Contact</NavLink></li>
                            <li><NavLink to={NavConstants.REQUEST_LIST}>My Request({this.props.numRequested})</NavLink></li>
                            <li><NavLink to={NavConstants.SEARCH}>Vehicle Search</NavLink></li>
                            <li><NavLink to={NavConstants.ABOUT}>About Us</NavLink></li>
                            <li><NavLink to={NavConstants.HOME}>Home</NavLink></li>
                        </div>
                    </div>

                    {this.props.device==='Mobile'?<a id='hamburger'  onClick={()=>{this.toggleMobileNav()}} title="Toggle Navigation Menu">
                            <img src={process.env.PUBLIC_URL + '/images/burger.png'} alt="Open Navigation" style={{width:'25px', height: '25px'}} />
                    </a>: ''}

                </div>

                {this.props.device==='Mobile'?<Drawer  id="mobileNav"  anchor='top' open={this.state.mobileNavShown} onClose={()=>{this.hideMobileNav()}} >
                    <li onClick={()=>{this.hideMobileNav()}}><NavLink to={NavConstants.HOME}>Home</NavLink></li>
                    <li onClick={()=>{this.hideMobileNav()}}><NavLink to={NavConstants.ABOUT}>About Us</NavLink></li>
                        <li onClick={()=>{this.hideMobileNav()}}><NavLink to={NavConstants.SEARCH}>Vehicle Search</NavLink></li>
                        <li onClick={()=>{this.hideMobileNav()}}><NavLink to={NavConstants.REQUEST_LIST}>My Request({this.props.numRequested})</NavLink></li>
                        <li onClick={()=>{this.hideMobileNav()}}><NavLink to={NavConstants.CONTACT}>Contact</NavLink></li>
                </Drawer>:''}
            </div>

        );

    }
}

const RoutedNav =  withRouter(Nav);

const mapDispatchToProps = {
};

const mapStateToProps = state => {
    return {
        numRequested : state.request.vehicles.length
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoutedNav);



