const innitialState = {
    searchOptions: null,
    mobileSubNavShown: false,
    selectedOptions: null,
    vehicles: null,
    heroPics:null,
    request:{
        vehicles: []
    },
    currentListPage: 0,
    sendStatus: null,
    requestDetails: null
};


export function mainReducer(state, action) {

    if (action.type.indexOf('@@redux/INIT')!== -1){
        return innitialState;
    }


    switch (action.type) {

        case 'UPDATE_SEARCH_OPTIONS':
            action.options.categories.unshift({id:"0", name: "All"});
            action.options.colors.unshift({id:"0", name: "All"});
            action.options.decades.unshift({id:"0", name: "All"});
            action.options.driversides.unshift({id:"0", name: "All"});
            action.options.interiorcolors.unshift({id:"0", name: "All"});
            action.options.makes.unshift({id:"0", name: "All"});
            action.options.origins.unshift({id:"0", name: "All"});

            let selectedOptions = {
                make: action.options.makes[0],
                category: action.options.categories[0],
                color: action.options.colors[0],
                decade: action.options.decades[0],
                driverside: action.options.driversides[0],
                interiorcolor: action.options.interiorcolors[0],
                origin: action.options.origins[0],
            }
            return {...state, ...{searchOptions: action.options}, selectedOptions};

        case 'CLEAR_FILTERS':
            let clearOptions = {
                make: state.searchOptions.makes[0],
                category: state.searchOptions.categories[0],
                color: state.searchOptions.colors[0],
                decade: state.searchOptions.decades[0],
                driverside: state.searchOptions.driversides[0],
                interiorcolor: state.searchOptions.interiorcolors[0],
                origin: state.searchOptions.origins[0],
            }

            return {...state, ...{selectedOptions: clearOptions}};

 case 'UPDATE_VEHICLES':
            return {...state, ...{vehicles: action.vehicles.vehicles}};

 case 'UPDATE_HERO_PICS':
            return {...state, ...{heroPics: action.pics}};


        case 'SET_LIST_PAGE':
            return {...state, ...{currentListPage: action.page}};

case 'ADD_TO_REQUEST':
            let addRequest = {...state.request}
            addRequest.vehicles = [...addRequest.vehicles]
            addRequest.vehicles.push(action.vehicle);
            return {...state, ...{request: addRequest}};


        case 'REMOVE_FROM_REQUEST':
            let remRequest = {...state.request}
            remRequest.vehicles = [...remRequest.vehicles].filter((vehicle)=>{
                return vehicle.carid!==action.vehicle.carid
            })
            return {...state, ...{request: remRequest}};


        case 'UPDATE_SEARCH_SELECTION':
            let newSelection = {...state.selectedOptions};
            newSelection[action.key] = action.value;
            return {...state, ...{selectedOptions: newSelection, currentListPage: 0}} ;

        case 'SET_REQUEST_DETAILS':
            return {...state, ...{requestDetails: action.details}} ;

        case 'CLEAR_REQUEST':
            return {...state, ...{ request:{ vehicles: []}, sendStatus: null, requestDetails: null}} ;


        case 'UPDATE_SEND_STATUS':
            return {...state, ...{sendStatus: action.status}} ;

        default :
            // console.log('-----------------NO handler for action type ---' + action.type + '------------------------------------------------');
            return state;
    }
}