const Constants = {
    ORIENTATION_PORTRAIT: 'Portrait',
    ORIENTATION_LANDSCAPE: 'Landscape',
    DEVICE_MOBILE: 'Mobile',
    DEVICE_DESKTOP: 'Desktop',
    MEDIA_QUERY_BREAK_POINT: 600,
    MEDIA_QUERY_RETINA_BREAK_POINT: 1080,
    API_PATH: '../api/',
    // API_PATH: 'test_api/',
    IMAGE_SRC:'http://uglyducklings.co.za/cars/',
    ITEMS_PER_PAGE: 24
}

export default Constants


