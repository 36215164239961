import React, { Component } from "react";
import {Carousel} from "react-responsive-carousel";
import Constants from "../../model/Constants";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Card} from "@material-ui/core";
import './About.css'
import {connect} from "react-redux";

class Contact extends Component {
    render() {

        const mobileoverride = this.props.device===Constants.DEVICE_MOBILE? {maxHeight: '200px'} : null;
        const imageBgStyle = this.props.device===Constants.DEVICE_DESKTOP? {
            backgroundImage:'url('+process.env.PUBLIC_URL + '/images/bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
            paddingRight: '50%'
        } : null;

        return (
            <div>
                <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>

                        {
                            this.props.heroPics != null ?
                                this.props.heroPics.map((pic, index) => {
                                    console.log('----------- pic: ', pic)
                                    return <div className='slideInner'  style={mobileoverride}  key={index}><img alt='' src={pic} /></div>
                                })
                                :
                                <Box className='mainContentBox'>
                                    <p>Loading</p>
                                </Box>
                        }

                    </Carousel>
                </Carousel>

                        <Box className='mainContentBox'>
                        <Grid container spacing={3}>
                            {/*<Grid item xs={12} md={6}>*/}
                            <Grid item>
                                <Card className={'Card'} style={{marin: '15px'}} style={imageBgStyle}>
                            <h2>ABOUT US</h2>

                           <p>
                               For over 20 years Ugly Ducklings has been a preferred supplier of action vehicles and car rentals for the movie, television and commercial industries in South Africa, with their head office in Cape Town, the heart of movie productions.
                           </p>
                            <p>
                            Ugly Ducklings is proud to offer the most comprehensive vehicle services in the industry, with access to hundreds of vehicles.  From vintage 1950’s to the pop culture of the 1980’s - if it has wheels, they will find it.
                            </p>
                            <p>
                            We also offer the opportunity to vehicle owners to earn income from their vehicles by registering their vehicles on our website for use in these industries.
                            </p>
                            <p>
                            Adriana and her team pride themselves in the service they provide. Having a long list of international and local movies in their portfolio, they know the industry well and offer vehicle solutions & logistics, including:
                            </p>
                            <ul>
                                <li>Action and Stunt Vehicles</li>
                                <li>Modifications & Build</li>
                                <li>Inhouse Mechanic</li>
                                <li>Transport of vehicles to and from set</li>
                                <li>On-Set Coordination</li>
                            </ul>
                        </Card>
                            </Grid>
                        </Grid>
                        </Box>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        // increment: () => dispatch(incAction),
    }
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        heroPics: state.heroPics
    };
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);
