import React, { Component } from "react";
import {Carousel} from "react-responsive-carousel";
import Constants from "../../model/Constants";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Card} from "@material-ui/core";
import './Contact.css'
import {connect} from "react-redux";

class Contact extends Component {
    render() {

        const mobileoverride = this.props.device===Constants.DEVICE_MOBILE? {maxHeight: '200px'} : null;
        const imageBgStyle = this.props.device===Constants.DEVICE_DESKTOP? {
            backgroundImage:'url('+process.env.PUBLIC_URL + '/images/bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain'
        } : null;

        return (
            <div>
                <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>

                        {
                            this.props.heroPics != null ?
                                this.props.heroPics.map((pic, index) => {
                                    console.log('----------- pic: ', pic)
                                    return <div className='slideInner'  style={mobileoverride}  key={index}><img alt='' src={pic} /></div>
                                })
                                :
                                <Box className='mainContentBox'>
                                    <p>Loading</p>
                                </Box>
                        }

                    </Carousel>
                </Carousel>

                        <Card className={'Card, contactCard'} style={imageBgStyle}>
                            <h2>CONTACT US</h2>

                            <h3 style={{margin:0}}> Bookings, general enquires and registration of vehicles<br/>
                                <span style={{color:'black'}}>Melissa Saayman</span></h3>
                            <Grid container >
                                <Grid item xs={12} >
                                    Office: <a href='tel:+27219877111'>+27 21 987 7111</a>
                                </Grid>
                                <Grid item xs={12} >
                                    Mobile: <a href="tel:+27784881909">+27(0)78 488 1909</a>
                                </Grid>
                                <Grid item xs={12}  >
                                    Email: <a href='mailto:info@uglyducklings.co.za'> info@uglyducklings.co.za</a>
                                </Grid>
                            </Grid>
                            <br/>
                            <h3 style={{margin:0}}>VEHICLE COORDINATION<br/>
                            <span style={{color:'BLACK'}}>Adriana du Toit</span></h3>
                                            <Grid container >
                                                <Grid item xs={12} >
                                                    Office: <a href='tel:+27219877111'>+27 21 987 7111</a>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    Mobile: <a href="tel:+27844445505">+27(0)84 444 5505</a>
                                                </Grid>
                                                <Grid item xs={12}  >
                                                    Email: <a href='mailto:adriana@uglyducklings.co.za'>adriana@uglyducklings.co.za</a>
                                                </Grid>
                                            </Grid>
                        </Card>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        // increment: () => dispatch(incAction),
    }
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        heroPics: state.heroPics
    };
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);
