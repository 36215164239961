import React from "react";
import ReactDOM from "react-dom";
import Main from "./view/Main";
import "./view/index.css";

ReactDOM.render(
    <Main/>,
    document.getElementById("root")
);


