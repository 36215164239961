import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import {Drawer, Button, Card} from "@material-ui/core";
import {updateSearchSelectionAction} from "../../redux/actions/actions";
import {connect} from "react-redux";
import VehicleList from "../components/VehicleList/VehicleList";

import './Search.css'
import Constants from "../../model/Constants";
import Grid from "@material-ui/core/Grid";
import SearchForm from '../components/SearchForm/SearchForm'
import '../PageContent.css'

class Search extends Component {

    constructor() {
        super();
        this.state = {
            drawerOpen: false,
        }

    }

    toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({...this.state, drawerOpen: open});
    };



    render() {

        const getFilter = (type, val, isLast)=>{
            let out =  val==='All'? '' : <div><span className={'filterName'}>{type}: </span><span className={'filterVal'}>{val}</span></div> ;
            return out
        }


        const noFilter = this.props.selectedOptions != null ? this.props.selectedOptions.make.name==='All'&& this.props.selectedOptions.category.name==='All'&& this.props.selectedOptions.color.name==='All'&& this.props.selectedOptions.decade.name==='All'&& this.props.selectedOptions.driverside.name==='All'&& this.props.selectedOptions.interiorcolor.name==='All'&& this.props.selectedOptions.origin.name==='All' : true

        if(this.props.device===Constants.DEVICE_MOBILE){
            return this.props.selectedOptions != null ?
                <Box className='mainContentBox'>
                    <Drawer style={{zIndex:0}} anchor='left' open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
                        <SearchForm device = {this.props.device} onCloseClicked={this.toggleDrawer(false)}/>
                    </Drawer>
                        <h2>VEHICLE SEARCH</h2>
                    <Card style={{marginBottom: '15px'}} className={'Card'}>
                        <h3 style={{margin: '0px'}}>{noFilter? 'No filters applied': 'Filtering with:'}</h3>
                        {getFilter('Make', this.props.selectedOptions.make.name, false)}
                        {getFilter('Category', this.props.selectedOptions.category.name, false)}
                        {getFilter('Color', this.props.selectedOptions.color.name, false)}
                        {getFilter('Decade', this.props.selectedOptions.decade.name, false)}
                        {getFilter('DriverSide', this.props.selectedOptions.driverside.name, false)}
                        {getFilter('Interior Color', this.props.selectedOptions.interiorcolor.name, false)}
                        {getFilter('Origin', this.props.selectedOptions.origin.name, false)}
                        <Button color="primary" style={{marginTop: '10px'}} variant="contained"
                                onClick={this.toggleDrawer(true)}>{noFilter?'Add Filters':'Change Filters'}</Button>
                    </Card>
                    <VehicleList device={this.props.device}/>
                </Box>
                :
                <Box className='mainContentBox'>
                    <p>Loading search options</p>
                </Box>
                ;
        }else{
            return this.props.searchOptions != null ?
                <Box className='mainContentBox'>
                    <Grid container spacing={3} className={'contentDiv'}>
                        <Grid item xs={12}>
                            <h2>VEHICLE SEARCH</h2>
                        </Grid>
                        <Grid item style={{flex: '0 0 220px'}} >
                            <Card>
                                <SearchForm />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={7} md={9} xl={10}>
                            <VehicleList device={this.props.device} />
                        </Grid>
                    </Grid>
                </Box>
                :
                <Box className='mainContentBox'>
                    <h2>VEHICLE SEARCH</h2>
                    <p>Loading search options</p>
                </Box>
                ;
        }

    }
}

    const mapDispatchToProps = dispatch => {
        return {
            // dispatching plain actions
            updateSearchSelectionAction: (key, value) => dispatch(updateSearchSelectionAction(key, value)),
        }
    }

const mapStateToProps = state => {
    return {
        searchOptions: state.searchOptions,
        selectedOptions: state.selectedOptions
    };
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(Search);
