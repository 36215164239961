import Constants from "../../model/Constants";
import SendStatusConstants from "../../model/SendStatusConstants";

export const getSearchOptionData = (dispatch, getState) => {

    const axios = require('axios').default;

    axios.get(Constants.API_PATH+'getFormData.php', {
    })
        .then(function (response) {
            dispatch(updateSearchOptionsAction(response.data));
        })
        .catch(function (error) {
            // console.log(error);
        })
        .then(function () {
        });
}

export const getVehiclesData = (dispatch, getState) => {

    const axios = require('axios').default;

    axios.get(Constants.API_PATH+'getVehicles.php', {
    })
        .then(function (response) {
            dispatch(updateVehiclesAction(response.data));
        })
        .catch(function (error) {
            // console.log(error);
        })
        .then(function () {
        });
}


export const getHeroPicsData = (dispatch, getState) => {

    const axios = require('axios').default;

    axios.get(Constants.API_PATH+'getHeroPics.php', {
    })
        .then(function (response) {
            dispatch(updateHeroPicsAction(response.data.heroPics));
        })
        .catch(function (error) {
            // console.log(error);
        })
        .then(function () {
        });
}

export const sendVehicleRequestAction = (dispatch, getState) => {

    let formVals = getState().requestDetails;
    const moment = require('moment');
    formVals.startDate = moment(formVals.startDate).format('DD MMMM YYYY');
    formVals.endDate = moment(formVals.endDate).format('DD MMMM YYYY');
    console.log('----------- formVals.startDate: ', formVals.startDate)
    console.log('----------- formVals.endDate: ', formVals.endDate)

    let requestedVehicles = getState().request.vehicles
    dispatch(updateSendStatusAction(SendStatusConstants.IN_PROGRESS));
    const axios = require('axios').default;

    let postVars = {...formVals, requestedVehicles:requestedVehicles}
    axios.post(Constants.API_PATH+'sendRequest.php', postVars)
        .then(function (response) {
            console.log('----------- response.data: ', response)
            if(response.statusText==='OK'){
                dispatch(updateSendStatusAction(SendStatusConstants.SUCCESS));
            }
        })
        .catch(function (error) {
            // console.log(error);
            dispatch(updateSendStatusAction(SendStatusConstants.FAILED, error));
        })
        .then(function () {
        });
}

export const updateSendStatusAction = (status, details) => ({
    type: 'UPDATE_SEND_STATUS',
    status: status,
    details: details
});

export const clearRequestAction ={
    type: 'CLEAR_REQUEST',
};

export const handleSendSuccessAction = {
    type: 'HANDLE_SEND_SUCCESS',
};

export const updateSearchOptionsAction = (options) => ({
    type: 'UPDATE_SEARCH_OPTIONS',
    options: options
});

export const updateVehiclesAction = (vehicles) => ({
    type: 'UPDATE_VEHICLES',
    vehicles: vehicles
});

export const updateHeroPicsAction = (pics) => ({
    type: 'UPDATE_HERO_PICS',
    pics: pics
});


export const updateSearchSelectionAction = (key, value) => ({
    type: 'UPDATE_SEARCH_SELECTION',
    key: key,
    value: value
});

export const clearFiltersAction = {
    type: 'CLEAR_FILTERS'
};


export const saveSourceIdAction = (sourceId) => ({
    type: 'SAVE_SOURCE_ID',
    sourceId: sourceId
});

export const addToRequestAction = (vehicle)=> ({
    type: 'ADD_TO_REQUEST',
    vehicle: vehicle
});

export const removeFromRequestAction = (vehicle)=> ({
    type: 'REMOVE_FROM_REQUEST',
    vehicle: vehicle
});

export const setListPageAction = (page)=> ({
    type: 'SET_LIST_PAGE',
    page: page
});


export const setRequestDetailsAction = (details)=> ({
    type: 'SET_REQUEST_DETAILS',
    details: details
});
