import React, { Component } from "react";
import {Box} from "@material-ui/core";
import {connect} from "react-redux";
import VehicleItem from "./VehicleItem";
import Grid from "@material-ui/core/Grid";
import ReactPaginate from "react-paginate";
import '../../pagination.css'
import {setListPageAction} from '../../../redux/actions/actions'
import Constants from "../../../model/Constants";

class VehicleList extends Component {


    render (){

        let filtered = this.props.vehicles==null? null : this.props.vehicles.filter((vehicle) => {

            let allow = true
            if(this.props.selectedOptions.make.name!=="All"){
                if(this.props.selectedOptions.make.name !== vehicle.Manufacturer){
                    allow=false;
                }
            }

            if(this.props.selectedOptions.category.name!=="All"){
                if(this.props.selectedOptions.category.name !== vehicle.Category){
                    allow=false;
                }
            }

            if(this.props.selectedOptions.color.name!=="All"){
                if(this.props.selectedOptions.color.name !== vehicle.Color){
                    allow=false;
                }
            }

            if(this.props.selectedOptions.decade.name!== "All"){
                if(this.props.selectedOptions.decade.name !== vehicle.Era){
                    allow=false;
                }
            }

            if(this.props.selectedOptions.driverside.name!=="All"){
                if(this.props.selectedOptions.driverside.name !== vehicle.Steering){
                    allow=false;
                }
            }

            if(this.props.selectedOptions.interiorcolor.name!=="All"){
                if(this.props.selectedOptions.interiorcolor.name !== vehicle.interiorcolor){
                    allow=false;
                }
            }

            if(this.props.selectedOptions.origin.name!=="All"){
                if(this.props.selectedOptions.origin.name !== vehicle.Origin){
                    allow=false;
                }
            }

            return allow
        })

        let numItems = filtered === null ? 0: filtered.length
        let numPages = filtered === null ? 0: filtered.length/Constants.ITEMS_PER_PAGE;

        let startInd = this.props.currentListPage * Constants.ITEMS_PER_PAGE
        let endInd = startInd + Constants.ITEMS_PER_PAGE -1
        if( endInd>numItems-1){endInd = numItems-1}

        if(filtered==null){
            return <p>Loading vehicles</p>
        }else{
            let shown = filtered.slice(startInd, endInd+1)
            return <Box>
                <Grid container spacing={2}>
                    { shown.map((veh, ind)=>{
                        return <Grid item key={veh.carid}   xs={12} sm={12} md={6} lg={4}>
                            <VehicleItem vehicleObj={veh} requestedVehicles={this.props.requestedVehicles}/>
                        </Grid>
                    })}
                </Grid>
                {numPages>1? <Box style={{paddingTop: '30px'}}><ReactPaginate
                        initialPage={this.props.currentListPage}
                        previousLabel={'<'}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        marginPagesDisplayed={this.props.device===Constants.DEVICE_MOBILE? 1 : 2}
                        pageRangeDisplayed={this.props.device===Constants.DEVICE_MOBILE? 3 : 5}
                        onPageChange={(event)=>{this.props.setListPage(event.selected)}}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}/></Box>
                        : '' }
            </Box>
                ;
        }




    }
}


const mapDispatchToProps = dispatch => {
    return {
        setListPage : (page)=>{
            dispatch(setListPageAction(page))
            window.scrollTo(0, 0)
        }
    };
}

const mapStateToProps = state => {
    return {
        vehicles: state.vehicles,
        selectedOptions: state.selectedOptions,
        requestedVehicles : state.request.vehicles,
        currentListPage: state.currentListPage
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VehicleList);
