import React, { Component } from "react";
import {Card} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import VehicleItem from "../components/VehicleList/VehicleItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import '../App.css'
import MomentUtils from '@date-io/moment';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {TextField} from "@material-ui/core";
import NavConstants from "../../model/NavConstants";
import {NavLink} from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import SendStatusConstants from "../../model/SendStatusConstants";
import {setRequestDetailsAction, sendVehicleRequestAction, clearRequestAction} from '../../redux/actions/actions'
import Constants from "../../model/Constants";

class RequestList extends Component {

    constructor() {
        super();
        this.state = {
            drawerOpen: true,
            formVals:{
                name: null,
                surname: null,
                email: null,
                mobile: null,
                city: null,
                startDate: new Date(),
                endDate: new Date()
            },
            errorObj : {}

        }

    }

    validate = ()=>{

        let errorObj = {}
        let validationSuccess = true;
        const checkEmptyFields = (type, ErrorStr)=>{
            if(this.state.formVals[type]!=null){
                errorObj[type] = null;
                //for empty strings
                if(this.state.formVals[type].length===0){
                    errorObj[type] = ErrorStr
                    validationSuccess=false;
                }
            }else{
                //for null values
                errorObj[type] = ErrorStr
                validationSuccess=false;
            }
        }
        const validateEmail = ()=> {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.formVals.email)===false)
            {
                errorObj.email = 'Invalid email address'
                validationSuccess=false;
            }
        }

        checkEmptyFields('name', 'Name is required');
        checkEmptyFields('surname','Surname is required');
        validateEmail()
        checkEmptyFields('mobile','Mobile number is required');
        checkEmptyFields('city','City is required');
        this.setState({...this.state, ...{errorObj: errorObj} });

        return validationSuccess
    }

    toggleDrawer = (open) => (event) => {
        this.setState({...this.state, drawerOpen: open});
    };

    getListContent(){
        return this.props.request.vehicles.length === 0 ? <Box><p>You dont have any vehicles in your selection.</p><NavLink to={NavConstants.SEARCH}><Button variant="contained">Find vehicles</Button></NavLink></Box> : (
            <Card style={{padding: '15px'}}>
                <h3>Selected vehicles</h3>
                <Grid container spacing={2}>
                    {this.props.request.vehicles.map((vehicle, index) => {
               return <Grid item key={index} xs={12} sm={6} md={4} >
                   <VehicleItem vehicleObj={vehicle} requestedVehicles={this.props.request.vehicles}/>
               </Grid>
            }
        )}</Grid></Card>)
    }

    getFormContent(){
     return this.props.request.vehicles.length > 0? <Grid item  xs={12} lg={3}  >
             <Card className={'Card'} style={{maxWidth:'220px'}}>
                 <form noValidate autoComplete="off">
                     <Grid container >
                         <Grid item  xs={12}>
                             <TextField
                                 id="name"
                                 label="First Name"
                                 error={this.state.errorObj.name!=null}
                                 helperText={this.state.errorObj.name? this.state.errorObj.name : null}
                                 onChange={(event)=>{this.upDateFormVals('name', event.target.value)}}
                             />
                         </Grid>
                         <Grid item  xs={12}>
                             <TextField
                                 id="surname"
                                 label="Last Name"
                                 error={this.state.errorObj.surname!=null}
                                 helperText={this.state.errorObj.surname? this.state.errorObj.surname : null}
                                 onChange={(event)=>{this.upDateFormVals('surname', event.target.value)}}
                             />
                         </Grid>
                         <Grid item  xs={12}>
                             <TextField
                                 id="email"
                                 label="Email"
                                 error={this.state.errorObj.email!=null}
                                 helperText={this.state.errorObj.email? this.state.errorObj.email : null}
                                 onChange={(event)=>{this.upDateFormVals('email', event.target.value)}}
                             />
                         </Grid>
                         <Grid item  xs={12}>
                             <TextField
                                 id="mobile"
                                 label="Mobile number"
                                 error={this.state.errorObj.mobile!=null}
                                 helperText={this.state.errorObj.mobile? this.state.errorObj.mobile : null}
                                 onChange={(event)=>{this.upDateFormVals('mobile', event.target.value)}}
                             />
                         </Grid>
                         <Grid item  xs={12}>
                             <TextField
                                 id="city"
                                 label="City"
                                 error={this.state.errorObj.city!=null}
                                 helperText={this.state.errorObj.city? this.state.errorObj.city : null}
                                 onChange={(event)=>{this.upDateFormVals('city', event.target.value)}}
                             />
                         </Grid>

                         <MuiPickersUtilsProvider utils={MomentUtils}>
                         <Grid item  xs={12}>

                                 <KeyboardDatePicker
                                     margin="normal"
                                     id="fromDate"
                                     label="Date required from"
                                     format="D MMM, YYYY"
                                     value={this.state.formVals.startDate}
                                     onChange={(date, value)=>{this.upDateFormVals('startDate', date._d)}}
                                     disablePast={true}
                                     disableToolbar={true}
                                     KeyboardButtonProps={{
                                         'aria-label': 'change date',
                                     }}
                                 />
                         </Grid>
                         <Grid item  xs={12}>

                                 <KeyboardDatePicker
                                     margin="normal"
                                     id="toDate"
                                     label="Date required to"
                                     format="D MMM, YYYY"
                                     value={this.state.formVals.endDate}
                                     onChange={(date, value)=>{
                                         this.upDateFormVals('endDate', date._d)}}
                                     disablePast={true}
                                     disableToolbar={true}
                                     KeyboardButtonProps={{
                                         'aria-label': 'change date',
                                     }}
                                 />

                         </Grid>
                         </MuiPickersUtilsProvider>
                         <Grid item  xs={12}>
                             <Button
                                 variant="contained"
                                 onClick={(event)=>{this.onSubmitPress()}}>Send enquiry</Button>
                         </Grid>
                     </Grid>
                 </form>

             </Card>
         </Grid>
         :''
    }

    upDateFormVals(type, value){
        let newFormVals = {...this.state.formVals}
        newFormVals[type]=value;
        this.setState({...this.state, ...{formVals: newFormVals}});
    }

    onSubmitPress(){
        if(this.validate()){
            this.props.sendRequest(this.state.formVals)
        }else{
            // console.log('----------- failed')
        }
    }


    render() {

        return <Grid container spacing={3} className={'contentDiv'}>
            <Grid item xs={12} style={{paddingBottom:0}}>
                <h2 style={{marginBottom:0}}>MY REQUEST</h2>
            </Grid>
            {this.getFormContent()}
            <Grid item xs={12} lg={9} >
                            {this.getListContent()}
                    </Grid>

            {this.props.sendStatus!= null ? <Dialog onClose={()=>{}} aria-labelledby="Sending request" open={true}>
                <Box style={{padding:'15px'}}>
                    <h3>Sending request</h3>
                    {this.props.sendStatus}
                    {this.props.sendStatus === SendStatusConstants.SUCCESS?
                        <Box style={{marginTop:'15px'}}><Button onClick={(event)=>{
                            this.props.clearRequest()
                        }} variant="contained">Close</Button></Box> : ''}
                </Box>
            </Dialog>: ''}
        </Grid>

    }

}


const mapDispatchToProps = dispatch => {
    return {
        sendRequest: (formVals)=>{
            dispatch(setRequestDetailsAction(formVals))
            dispatch(sendVehicleRequestAction)
        },
        clearRequest: ()=>{
            dispatch(clearRequestAction)
        }
    };
}

const mapStateToProps = state => {
    return {
        request : state.request,
        sendStatus: state.sendStatus
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestList);
