import React, { Component } from "react";
import {Card} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {addToRequestAction} from "../../../redux/actions/actions";
import {removeFromRequestAction} from "../../../redux/actions/actions";
import {connect} from "react-redux";
import Constants from "../../../model/Constants";

class VehicleItem extends Component {


    render (){

        let isInRequest = this.props.requestedVehicles.find((vehicle)=>{ return vehicle.carid === this.props.vehicleObj.carid })!=null

        return <Card style={{padding: '15px'}}>
                <img src={Constants.IMAGE_SRC+this.props.vehicleObj.vthumb} alt='' style={{objectFit: 'contain', width:'100%', }} />

            <p>{this.props.vehicleObj.Vehicle}<br/>
                {/*{this.props.vehicleObj.Category}<br/>*/}
                {/*{this.props.vehicleObj.Color}<br/>*/}
                {/*{this.props.vehicleObj.Era}<br/>*/}
                {/*{this.props.vehicleObj.Manufacturer}<br/>*/}
                {/*{this.props.vehicleObj.Origin}<br/>*/}
                {/*{this.props.vehicleObj.Steering}<br/>*/}
                {/*{this.props.vehicleObj.Year}<br/>*/}
                {/*{this.props.vehicleObj.city}<br/>*/}
                {/*{this.props.vehicleObj.interiorcolor}<br/>*/}
                {/*{this.props.vehicleObj.vimage}<br/>*/}
                {/*{this.props.vehicleObj.vthumb}<br/>*/}

            </p>
            {isInRequest ? <Button variant="contained" onClick={(event)=>{this.props.removeFromRequest(this.props.vehicleObj)}}>Remove from Request</Button>: <Button variant="contained" onClick={(event)=>{this.props.addToRequest(this.props.vehicleObj)}}>Add to Request</Button>}

        </Card>
        ;

    }
}


const mapDispatchToProps = dispatch => {
    return {
        addToRequest: (vehicleObj) => dispatch(addToRequestAction(vehicleObj)),
        removeFromRequest: (vehicleObj) => dispatch(removeFromRequestAction(vehicleObj)),
    }
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        searchOptions: state.searchOptions,
    };
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(VehicleItem);

