import React, { Component } from "react";
import {connect} from "react-redux";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Home.css'
import '../App.css'
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Card} from "@material-ui/core";

import NavConstants from "../../model/NavConstants";
import {NavLink} from "react-router-dom";
import Constants from "../../model/Constants";
// import Constants from "../../model/Constants";
// import VehicleItem from "../components/VehicleList/VehicleItem";
class Home extends Component {




    render() {

        const mobileoverride = this.props.device===Constants.DEVICE_MOBILE? {maxHeight: '200px'} : null;

        const imageBgStyle = this.props.device===Constants.DEVICE_DESKTOP? {
            backgroundImage:'url('+process.env.PUBLIC_URL + '/images/bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'contain',
            paddingRight: '50%'
        } : null;

        return (
            <div>

                <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false}>

                    {
                        this.props.heroPics != null ?
                            this.props.heroPics.map((pic, index) => {
                                console.log('----------- pic: ', pic)
                                return <div className='slideInner'  style={mobileoverride}  key={index}><img alt='' src={pic} /></div>
                            })
                        :
                        <Box className='mainContentBox'>
                            <p>Loading</p>
                        </Box>
                    }

                </Carousel>

                <Box className='mainContentBox'>
                    <Grid container spacing={3}>
                        {/*<Grid item xs={12} md={6}>*/}
                        <Grid item>
                            <Card className={'Card'} style={{marin: '15px'}} style={imageBgStyle}>
                                <h2>HOME</h2>
                            <p>Ugly Ducklings specializes in leasing automobiles for motion pictures and commercial photography. This also includes special events and weddings, film, television, theatre production and promotional work (advertising industries). We have access to hundreds of vehicles and if there is something specific you are looking for then simply request it. From the vintage 1950's to the pop culture of the 1980's, if it has wheels and you need it we will find it.</p>

                            <p>South Africa offers great locations for movies and filmshoots. With significant experience and a rich, varied portfolio, Ugly Ducklings is an invaluable resource when it comes to providing standard and customized vehicles for the film industry. Ugly Ducklings can cater to all your vehicle needs, however unusual they might be, and is your first choice for the best in movie and filmset vehicle rental.</p>

                            <p>Ugly Ducklings is able to offer very competitive rates. Knowing the industry well, it is possible not only to provide the best, but Ugly Ducklings can also prove to be a resource of industry knowledge. If getting the job done is what matters then Ugly Ducklings will not dissapoint.</p>
                            </Card>
                        </Grid>

                    </Grid>
                </Box>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // dispatching plain actions
        // increment: () => dispatch(incAction),
    }
}

const mapStateToProps = state => {
    // console.log(state)
    return {
        searchOptions: state.searchOptions,
        heroPics: state.heroPics
    };
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
