import React, { Component } from "react";
import App from './App'
import {Provider} from 'react-redux';
import {store} from '../redux/Store'
import {getSearchOptionData, getVehiclesData, getHeroPicsData} from '../redux/actions/actions'
import MediaQuery from 'react-responsive'
import Constants from "../model/Constants";

class Main extends Component {

    componentDidMount() {
        store.dispatch(getSearchOptionData);
        store.dispatch(getVehiclesData);
        store.dispatch(getHeroPicsData);
    }


    render() {
        return (
            <Provider store={store}>
                {/*<MediaQuery orientation='landscape'>*/}
                    <MediaQuery minWidth={Constants.MEDIA_QUERY_BREAK_POINT + 1}>
                        <App orientation={Constants.ORIENTATION_LANDSCAPE} device={Constants.DEVICE_DESKTOP} />
                    </MediaQuery>
                    <MediaQuery maxWidth={Constants.MEDIA_QUERY_BREAK_POINT}>
                        <App orientation={Constants.ORIENTATION_LANDSCAPE} device={Constants.DEVICE_MOBILE} />
                    </MediaQuery>
                {/*</MediaQuery>*/}

                {/*<MediaQuery orientation='portrait'>*/}
                {/*    <MediaQuery minWidth={Constants.MEDIA_QUERY_BREAK_POINT + 1}>*/}
                {/*        <App orientation={Constants.ORIENTATION_PORTRAIT} device={Constants.DEVICE_DESKTOP}/>*/}
                {/*    </MediaQuery>*/}
                {/*    <MediaQuery maxWidth={Constants.MEDIA_QUERY_BREAK_POINT}>*/}
                {/*        <App orientation={Constants.ORIENTATION_PORTRAIT} device={Constants.DEVICE_MOBILE}/>*/}
                {/*    </MediaQuery>*/}
                {/*</MediaQuery>*/}





            </Provider>
        );
    }
}

export default Main;