import React, { Component } from "react";
import {
    Route,
    HashRouter,
} from "react-router-dom";
import Home from "./sections/Home";
import About from "./sections/About";
import Search from "./sections/Search";
import RequestList from "./sections/RequestList";
import NavConstants from "../model/NavConstants"
import Contact from "./sections/Contact";
import {store} from '../redux/Store'
import {getSearchOptionData} from '../redux/actions/actions'
import Nav from './components/Nav/Nav';
import {Helmet} from "react-helmet";

import './App.css';

class App extends Component {

    componentDidMount() {
        store.dispatch(getSearchOptionData);
    }

    render() {
        return (

                <HashRouter>
                {/*<HashRouter basename="/build">*/}
                    <div className={`App ${this.props.device} ${this.props.orientation}`}>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Ugly Ducklings | Cars and vehicles for movies and photoshoots</title>
                            <meta name="keywords" content="Ugly Ducklings | Cars and vehicles for movies and photoshoots" />
                            <meta name="description" content="Ugly Ducklings | Cars and vehicles for movies and photoshoots" />
                            <meta name="rating" content="Commerce" />
                            <meta name="ROBOTS" content="ALL,index,follow" />
                        </Helmet>
                        <Nav {...this.params} { ...this.props} />
                        <div className="contentWrapper">
                            <Route exact path={NavConstants.HOME} render={() => <Home{...this.props} />}/>
                            <Route exact path={NavConstants.ABOUT} render={() => <About{...this.props} />}/>
                            <Route path={NavConstants.SEARCH} render={() => <Search{...this.props} />}/>
                            <Route path={NavConstants.REQUEST_LIST} render={() => <RequestList{...this.props} />}/>
                            <Route path={NavConstants.CONTACT} render={() => <Contact{...this.props} />}/>
                        </div>
                    </div>
                </HashRouter>
        );
    }
}

export default App;