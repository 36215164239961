import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {updateSearchSelectionAction, clearFiltersAction} from "../../../redux/actions/actions";
import {connect} from "react-redux";
import {Button} from "@material-ui/core";

class SearchForm extends Component {

    handleChange = (attribute, event) => {
        this.props.updateSearchSelectionAction(attribute, event.target.value);
    }

    render() {

        let formStyle = {
            formBox:{
                width: '180px',
                padding:'15px',
                paddingTop: this.props.device==='Mobile'? '90px': '15px'
            },
            formControl:{
                minWidth:180,
                marginBottom: 10
            }

        }

        const noFilter = this.props.selectedOptions != null ? this.props.selectedOptions.make.name==='All'&& this.props.selectedOptions.category.name==='All'&& this.props.selectedOptions.color.name==='All'&& this.props.selectedOptions.decade.name==='All'&& this.props.selectedOptions.driverside.name==='All'&& this.props.selectedOptions.interiorcolor.name==='All'&& this.props.selectedOptions.origin.name==='All' : true

        return <Box style={formStyle.formBox}>

            {this.props.device==='Mobile'?<Box style={{float: 'right'}}>
                <img alt='close' onClick={(event)=>{this.props.onCloseClicked()}} style={{width:'20px', }} src={process.env.PUBLIC_URL + '/images/close_but.png'} />
            </Box>:''}
            <FormControl style={formStyle.formControl}>
                <InputLabel id="make-label">Make</InputLabel>
                <Select
                    labelId="make"
                    id="make"
                    // value={}
                    value={this.props.selectedOptions.make}
                    onChange={ (event)=>{this.handleChange('make', event)}}
                >
                    {this.props.searchOptions.makes.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl style={formStyle.formControl}>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                    labelId="category"
                    id="category"
                    value={this.props.selectedOptions.category}
                    onChange={ (event)=>{this.handleChange('category', event)}}
                >
                    {this.props.searchOptions.categories.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl style={formStyle.formControl}>
                <InputLabel id="color-label">Color</InputLabel>
                <Select
                    labelId="color"
                    id="color"
                    value={this.props.selectedOptions.color}
                    onChange={ (event)=>{this.handleChange('color', event)}}
                >
                    {this.props.searchOptions.colors.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl style={formStyle.formControl}>
                <InputLabel id="decade-label">Decade</InputLabel>
                <Select
                    labelId="decade-label"
                    id="decade"
                    value={this.props.selectedOptions.decade}
                    onChange={ (event)=>{this.handleChange('decade', event)}}
                >
                    {this.props.searchOptions.decades.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl style={formStyle.formControl}>
                <InputLabel id="driverside-label">Driver side</InputLabel>
                <Select
                    labelId="driverside-label"
                    id="driverside"
                    value={this.props.selectedOptions.driverside}
                    onChange={ (event)=>{this.handleChange('driverside', event)}}
                >
                    {this.props.searchOptions.driversides.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl style={formStyle.formControl}>
                <InputLabel id="interiorcolor-label">Interior Color</InputLabel>
                <Select
                    labelId="interiorcolor-label"
                    id="interiorcolor"
                    value={this.props.selectedOptions.interiorcolor}
                    onChange={ (event)=>{this.handleChange('interiorcolor', event)}}
                >
                    {this.props.searchOptions.interiorcolors.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            <FormControl style={formStyle.formControl}>
                <InputLabel id="origin-label">Origin</InputLabel>
                <Select
                    labelId="origin-label"
                    id="origin"
                    value={this.props.selectedOptions.origin}
                    onChange={ (event)=>{this.handleChange('origin', event)}}
                >
                    {this.props.searchOptions.origins.map((key, index) => {
                        return <MenuItem key={index} value={key}>{key.name}</MenuItem>
                    })}
                </Select>
            </FormControl>

            {noFilter? '' : <Button style={{marginTop: '10px'}} variant="contained"
                    onClick={(event)=>{this.props.clearFilters()}}>Clear Filters</Button>}

        </Box>
            ;

    }
}

    const mapDispatchToProps = dispatch => {
        return {
            // dispatching plain actions
            clearFilters: () => dispatch(clearFiltersAction),
            updateSearchSelectionAction: (key, value) => dispatch(updateSearchSelectionAction(key, value)),
        }
    }

const mapStateToProps = state => {
    return {
        searchOptions: state.searchOptions,
        selectedOptions: state.selectedOptions
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm);
