// @flow

import {compose, applyMiddleware, createStore} from 'redux';
import {mainReducer} from './reducers/reducers';
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk';


const loggerMiddleware = createLogger({
    level: 'console',
    logErrors: true,
    predicate: (state, action) => {
        // console.log('---redux log---------' + action.type + '---------------------------------------');
    }
});

export const store = createStore(
    mainReducer,
    compose(
        applyMiddleware(loggerMiddleware, thunk)
    )
);
